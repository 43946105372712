import { PimcoreRelation } from '../pimcore/PimcoreRelation'
import { Configuration, ConfigurationImport } from '@/models/order/Configuration'
import { BranchOffice } from '@/models/branchoffice/BranchOffice'
import { Object_Order, UpdateOrderInput } from '@/api/graphql/schema/app.graphql.types'
import { ContactData } from './ContactData'
import { InvoicingData } from './InvoicingData'
import { StateBranch, StateInquiry, StateInvoice, StateOrder } from './workflow/States'
import { PimcoreAsset } from '../pimcore/PimcoreAsset'
import { PimcoreNode } from '../pimcore/PimcoreNode'
import { get, ObjectParams } from '@/common/custom/ts-customization'
import { PimcoreApiUser } from '@/models/pimcore/PimcoreApiUser'
import { History } from '@/models/order/OrderHistory'
import { FilterVisibility } from '@/models/order/OrderFilter'

/** Poptávka / Objednávka */
export enum ORDER_TYPE {
  INQUIRY = 'inquiry',
  ORDER = 'order'
}

export type OrderType = ORDER_TYPE.INQUIRY | ORDER_TYPE.ORDER

export enum ATTACHEMENT_TYPE {
  IMAGE = 'image',
  DOC = 'document',
  TEXT = 'text'
}

export enum INVOICED_ENTITY {
  company = 'company',
  person = 'person'
}

/**
 * ~
 */
export type Order = PimcoreNode & {

  // ---  ---  ---  ---  ---  ---  ---  ---

  /** shoud be same as PimcoreNode key. set only ONCE */
  readonly orderNumber: string
  readonly orderType: OrderType
  state: StateOrder | StateInquiry

  invoicingData: InvoicingData
  contactData: ContactData
  configurations: Configuration[]
  configuratorVersion?: '1' | '2'
  customerCalledAt?: string | null

  // ---  ---  ---  ---  ---  ---  ---  ---

  readonly creationDateInquiry: string
  readonly creationDateOrder: string
  readonly assetFolderId: number

  /** is different than orderNumber, regards the ID of invoice document */
  invoiceNumber: string
  invoiceState: StateInvoice
  invoiceAsset: PimcoreAsset
  invoiceProformaState: StateInvoice
  invoiceProformaAsset: PimcoreAsset

  // ---  ---  ---  ---  ---  ---  ---  ---

  branchOffice: BranchOffice
  branchCommissionState: StateBranch
  allocatedBranch: BranchOffice

  // ---  ---  ---  ---  ---  ---  ---  ---

  isPriority: boolean
  isEmergency: boolean

  internalNote: string
  cancellationNote: string

  createdBy: PimcoreApiUser | null
  acceptedBy: PimcoreApiUser | null

  history: History[]

  totalPriceWithVat: string
  totalPriceWithoutVat: string
  isComplaint: string
  signedOrderConfirmation?: {
    id: string
  } | null
  invoice?: {
    id
    workflowState
    proformaLabel
    invoiceLabel
    proformaColor
    invoiceColor
    proforma: PimcoreAsset | null,
    invoice: PimcoreAsset | null,
  }| null
}
export const Order = (): Order => ({
  orderNumber: '',
  orderType: ORDER_TYPE.ORDER,
  state: '',
  invoicingData: InvoicingData(),
  contactData: ContactData(),
  configurations: [Configuration('Konfigurace 1')],
  configuratorVersion: '1',
  customerCalledAt: null,
  assetFolderId: 0,
  creationDateInquiry: '',
  creationDateOrder: '',
  invoiceNumber: '',
  invoiceState: '',
  invoiceAsset: PimcoreAsset(),
  invoiceProformaState: '',
  invoiceProformaAsset: PimcoreAsset(),
  branchOffice: BranchOffice(),
  allocatedBranch: BranchOffice(),
  branchCommissionState: '',
  isPriority: false,
  isEmergency: false,
  internalNote: '',
  cancellationNote: '',
  createdBy: null,
  acceptedBy: null,
  history: [],
  totalPriceWithVat: '',
  totalPriceWithoutVat: '',
  isComplaint: 'false',
  signedOrderConfirmation: null,
  invoice: null,
})


export const OrderImport = (o?: Object_Order): Order => {
  if (!o) return Order()
  const configurations: Configuration[] = []
  if (o.configurations) {
    for (const c of o.configurations) {
      if (c) {
        configurations.push({
          ...ConfigurationImport(c),
          selectedConfiguration: c?.selectedConfiguration || '',
          selectedConfigurationApp: c?.selectedConfigurationApp || '',
          selectedConfigurationWeb: c?.selectedConfigurationWeb || '',
          selectedPrice: c?.selectedPrice || '',
        })
      }
    }
  }
  else {
    configurations.push(Configuration('Konfigurace 1'))
  }

  const imported: Order = {
    ...PimcoreNode(o.id, o.key, o.fullpath),

    orderType: get<OrderType>(o.orderType, ORDER_TYPE.ORDER),
    orderNumber: o.orderNumber || '',
    state: get<StateOrder>(o.state, ''),

    invoicingData: InvoicingData(o.invoicingData),
    contactData: ContactData(o.contactData),
    configurations,
    configuratorVersion: o.configuratorVersion || '1',
    customerCalledAt: o.customerCalledAt || null,

    invoiceNumber: o.invoiceNumber || '',
    invoiceState: get<StateInvoice>(o.invoiceState, ''),
    invoiceAsset: PimcoreAsset(o.invoiceAsset),
    invoiceProformaState: get<StateInvoice>(o.invoiceProformaState, ''),
    invoiceProformaAsset: PimcoreAsset(o.invoiceProformaAsset),

    branchOffice: BranchOffice(o.branchOffice),
    allocatedBranch: BranchOffice(o.allocatedBranch),
    branchCommissionState: get<StateBranch>(o.branchCommissionState, ''),
    cancellationNote: o.cancellationNote || '',

    creationDateInquiry: o.creationDateInquiry || '',
    creationDateOrder: o.creationDateOrder || '',
    assetFolderId: o.assetFolderId || 0,

    isPriority: o.isPriority || false,
    isEmergency: o.isEmergency || false,
    internalNote: o.internalNote || '',
    createdBy: o.createdBy ? PimcoreApiUser(o.createdBy) : null,
    acceptedBy: o.acceptedBy ? PimcoreApiUser(o.acceptedBy) : null,
    history: o.history ? o.history as History[] : [],
    totalPriceWithVat: o.totalPriceWithVat || '',
    totalPriceWithoutVat: o.totalPriceWithoutVat || '',
    isComplaint: o.isComplaint || 'false',
    signedOrderConfirmation: o.signedOrderConfirmation || null,
    invoice: o.invoice || null,
  }
  return imported
}

export const OrderExport = (o: Order): UpdateOrderInput => {
  const exported: UpdateOrderInput = {
    invoiceState: o.invoiceState,
    invoiceAsset: PimcoreRelation('asset', o.invoiceAsset),
    invoiceProformaState: o.invoiceProformaState,
    invoiceProformaAsset: PimcoreRelation('asset', o.invoiceProformaAsset),
    branchOffice: PimcoreRelation('object', o.branchOffice),
    branchCommissionState: o.branchCommissionState,
    isPriority: o.isPriority,
    isEmergency: o.isEmergency,
    internalNote: o.internalNote,
    cancellationNote: o.cancellationNote,
  }

  for (const k of ObjectParams(exported)) {
    if (!exported[k] && typeof exported[k] !== 'boolean') delete exported[k]
  }

  return exported
}

/** @deprecated */
export type OrderSummary = PimcoreNode & {
  /** Číslo objednávky */
  orderNumber: string
  /** Priorita objednávky */
  isPriority: boolean
  /** ... */
  isEmergency: boolean
  /** Jméno & Příjmení */
  entityName: string
  /** Obec */
  city: string
  /** Specifikace (C / P / zlatý dub) */
  specification: string
  /** Pobočka (Ústí nad Labem) */
  branchName: string
  /** Montážna skupina */
  workerGroupID: string

  /** Datum montáže - zobrazenie */
  displayInstallationDate: string

  installationDate: string
  installationMorning: boolean
  installationAfternoon: boolean

  /** stav */
  state: StateOrder

  inquiryCreated: string
}

export interface FilterTab  {
  state?: string[],
  tab?: string,
  excludeCentralBranch?: boolean,
  branchOfficesId?: number[],
  branchOfficeMode?: string[],
}

export interface Tab  {
  key: number,
  filter: FilterTab,
  label: string,
  adminOnly: boolean,
  withSubordinateOnly: boolean,
  filterVisibility: FilterVisibility
}
