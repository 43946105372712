import { Asset, Maybe, Object_OrderDoorConfiguration, UpdateOrderDoorConfigurationInput } from '@/api/graphql/schema/app.graphql.types'
import { FormSelection, get, ObjectParams } from '@/common/custom/ts-customization'
import { PimcoreAsset } from '../pimcore/PimcoreAsset'
import { PimcoreNode, PimcoreNodeStrip } from '../pimcore/PimcoreNode'
import { PimcoreRelation } from '../pimcore/PimcoreRelation'

import { WorkerGroup } from '../workers/WorkerGroup'
import { AdditionalService, AdditionalServiceImport } from './configuration/AdditionalService'
import { DoorParameters } from './configuration/DoorParameters'
import { PriceItem, PriceItemImport } from './configuration/PriceItem'
import { ProtocolData, ProtocolDataImport } from './configuration/ProtocolData'
import { StateConfig } from './workflow/States'
import { ServiceRequest } from '../serviceRequest/serviceRequest'

export type DirectSellTransport = 'personalcollection' | 'transport'
export const DirectSellTransport: FormSelection<DirectSellTransport> = {
  personalcollection: { key: 'personalcollection', label: 'Osobní odběr' },
  transport: { key: 'transport', label: 'Přepravní služba' },
}

export type ElevatorSelection = 'Ano' | 'Ne' | ''

export enum CONFIGURATOR_VALUES {
  UNKNOWN = 'Nevím'
}

export type Configuration = PimcoreNode & {
  name: string
  quantity: number
  note: string

  // ---  ---  ---  ---  ---  ---  ---  --- Installation/Assembly

  installationDate: string
  installationMorning: boolean
  installationAfternoon: boolean
  installationEvening: boolean

  installationState: StateConfig
  workerGroup: WorkerGroup

  // ---  ---  ---  ---  ---  ---  ---  --- Shipping Address

  directSellTransport: DirectSellTransport
  shippingStreet: string
  shippingStreetNumber: string
  shippingCity: string
  shippingPostcode: string

  // ---  ---  ---  ---  ---  ---  ---  --- Installation/Assembly Address

  street: string
  streetNumber: string
  city: string
  postcode: string
  floor: string
  elevator: ElevatorSelection

  // ---  ---  ---  ---  ---  ---  ---  --- Main Data

  parameters: DoorParameters // JSON
  additionalServices: AdditionalService[] // JSON
  otherAdditionalServices: PriceItem[] // JSON
  priceItems: PriceItem[] // JSON
  attachments: PimcoreAsset[]
  documents: PimcoreAsset[]
  protocolData: ProtocolData[]
  serviceRequestId: string
  serviceRequest: ServiceRequest[]|null
  selectedConfiguration?: string
  selectedConfigurationApp?: string
  selectedConfigurationWeb?: string
  selectedPrice?: string

  // ---  ---  ---  ---  ---  ---  ---  --- Other Metadata

  attachmentFolderId: number
  documentFolderId: number
  selectedFrame: string | null

}
export const Configuration = (confName?: string): Configuration => ({
  ...PimcoreNode(),
  name: confName || '',
  quantity: 1,
  note: '',

  installationDate: '',
  installationMorning: false,
  installationAfternoon: false,
  installationEvening: false,
  installationState: '',
  workerGroup: WorkerGroup(),

  street: '',
  streetNumber: '',
  city: '',
  postcode: '',
  floor: '',
  elevator: '',
  directSellTransport: 'personalcollection',
  shippingStreet: '',
  shippingStreetNumber: '',
  shippingCity: '',
  shippingPostcode: '',
  parameters: DoorParameters(), // JSON
  additionalServices: [], // JSON
  otherAdditionalServices: [], // JSON
  priceItems: [], // JSON
  protocolData: [], // JSON
  serviceRequestId: '',
  serviceRequest: null,
  attachments: [],
  attachmentFolderId: 0,
  documents: [],
  documentFolderId: 0,
  selectedConfiguration: '',
  selectedPrice: '',
  selectedFrame: '',
})

export const ConfigurationImport = (o?: Maybe<Object_OrderDoorConfiguration>): Configuration => {
  const c = Configuration()
  if (!o) return c

  for (const k of Object.keys(c) as Array<keyof Configuration>) {
    switch (k) {
      case 'parameters':              c[k] = DoorParameters(o.parameters); break
      case 'additionalServices':      c[k] = AdditionalServiceImport(o.additionalServices); break
      case 'otherAdditionalServices': c[k] = PriceItemImport(o.otherAdditionalServices); break
      case 'protocolData':            c[k] = ProtocolDataImport(o.protocolData); break
      case 'priceItems':              c[k] = PriceItemImport(o.priceItems); break
      case 'attachments':             c[k] = get<Maybe<Asset>[]>(o.attachments, []).map(a => PimcoreAsset(a)); break
      case 'attachmentFolderId':      c[k] = get<number>(o.attachmentFolderId, 0); break
      case 'documents':               c[k] = get<Maybe<Asset>[]>(o.documents, []).map(a => PimcoreAsset(a)); break
      case 'documentFolderId':        c[k] = get<number>(o.documentFolderId, 0); break
      case 'workerGroup':             c[k] = WorkerGroup(o.workerGroup); break
      default:                        if (o[k]) (c[k] as any) = o[k]
    }
  }
  return c
}

export const ConfigurationExport = (c: Configuration): UpdateOrderDoorConfigurationInput => {
  const exported: UpdateOrderDoorConfigurationInput = {}
  for (const k of ObjectParams(c)) {
    switch (k) {
      case 'parameters':              exported[k] = JSON.stringify(c.parameters); break
      case 'additionalServices':      exported[k] = JSON.stringify(c.additionalServices); break
      case 'otherAdditionalServices': exported[k] = JSON.stringify(c.otherAdditionalServices); break
      case 'protocolData':            exported[k] = c.protocolData.map(i => PimcoreRelation('object', i)!); break
      case 'priceItems':              exported[k] = JSON.stringify(c.priceItems); break
      case 'attachments':             exported[k] = c.attachments.map(a => PimcoreRelation('asset', a)!); break
      case 'documents':               exported[k] = c.documents.map(a => PimcoreRelation('asset', a)!); break
      case 'workerGroup':             exported[k] = PimcoreRelation('object', c.workerGroup); break
      default: (exported[k] as any) = c[k]
    }
    if (!exported[k] && typeof exported[k] !== 'boolean') delete exported[k]
  }

  return exported
}


export const ConfigurationCopy = (c: Configuration, index: number): Configuration => PimcoreNodeStrip<Configuration>({
  ...c,
  name: `Kopie konfigurace ${index + 1}`,

  installationDate: '',
  installationMorning: false,
  installationAfternoon: false,

  installationState: 'waitingForTerm',
  workerGroup: WorkerGroup(),

  parameters: DoorParameters(),
  additionalServices: [],
  otherAdditionalServices: [],
  priceItems: [],
  attachments: [],
  documents: [],
  protocolData: [],

  attachmentFolderId: 0,
  documentFolderId: 0,

})
